body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #424242;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.slider-board {
  margin-top: 18px;
  background-image: url(imgs/Desktop\ HD\ TM\ all.png);
  background-size: cover;
  background-position: center;
}

.slider-board div {
  display: flex;
  justify-content: center;
  margin: 1px;
}

.slider-msg {
  font-size: 24px;
}

.btn-new-game {
  margin-top: 20px;
}

.btn-new-game button {
  outline: 0;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: #45884e;
  border: none;
  padding: 1.6em 4em;
}

.slider-board div span, .slider-board div div {
  background: #5f5e88;
  width: 46px;
  height: 46px;
  margin: 1px;
}

.slider-board div span img, .slider-board div div img {
    width: 100%;
}

.slider-board div span.empty, .slider-board div div.empty {
  opacity: 0;
}
